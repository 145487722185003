import { createRouter, createWebHistory } from "vue-router";
import { getUserState } from "../util/firebase";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../views/Home.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/register",
      component: () => import("../views/Register.vue"),
    },
    {
      path: "/sign-in",
      component: () => import("../views/SignIn.vue"),
    },
    {
      path: "/users",
      component: () => import("../views/ManageUsers.vue"),
    },
    {
      path: "/add-task",
      component: () => import("../views/AddTask.vue"),
      meta: { requiresAdmin: true },
    },
    {
      path: "/add-course",
      component: () => import("../views/AddCourse.vue"),
      meta: { requiresAdmin: true },
    },
    {
      path: "/activate-course/:id?",
      component: () => import("../views/ActivateCourse.vue"),
      meta: { requiresAdmin: true },
    },
    {
      path: "/not-authorized",
      component: () => import("../views/NotAuthorized.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresUnauth = to.matched.some(
    (record) => record.meta.requiresUnauth
  );

  const { user, isAdmin } = await getUserState();

  if (requiresAuth && !user) next("/login");
  else if (requiresUnauth && user) next("/");
  else if (requiresAdmin && !isAdmin) next("/not-authorized");
  else next();
});

export default router;
