import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from "@firebase/firestore";
import { query, where } from "firebase/firestore";

/**
 * Note: This controller is not reactive as the other ones are. Answers are gotten on demand as its the most volatile datasource and we can't rely on a cache to give accurate results in most cases
 * This problem will grow exponentially as usercount grows, so to prevent this BaseController is not extended here
 */
export class CourseAnswerController {
  private collectionRef = collection(getFirestore(), "answers");
  constructor() {}

  async getAnswersForCourse(courseId: string) {
    const q = query(this.collectionRef, where("courseId", "==", courseId));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => doc.data() as Answer);
  }

  async getAnswersForUser(userId: string) {
    const q = query(this.collectionRef, where("userId", "==", userId));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => doc.data() as Answer);
  }

  async getAnswersForTask(taskid: string) {
    const q = query(this.collectionRef, where("taskid", "==", taskid));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => doc.data() as Answer);
  }

  async createAnswer(
    courseId: string,
    taskId: string,
    userId: string,
    answer: boolean
  ) {
    return addDoc(this.collectionRef, {
      courseId: courseId,
      taskId: taskId,
      userId: userId,
      answer: answer,
    }).then((doc) => {
      updateDoc(doc, { uid: doc.id });
    });
  }
  async deleteAnswer(courseId: string, userId: string) {
    const q = query(
      this.collectionRef,
      where("courseId", "==", courseId),
      where("userId", "==", userId)
    );
    const docs = await getDocs(q);
    //The above query should always just return one document
    return deleteDoc(docs.docs[0].ref);
  }
}
