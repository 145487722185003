import {
  createUserWithEmailAndPassword,
  getIdTokenResult,
  signInWithEmailAndPassword,
  signOut,
} from "@firebase/auth";
import { Ref } from "@vue/reactivity";
import { Unsubscribe } from "@firebase/util";

import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { ref, computed, onMounted, onUnmounted } from "vue";
import { initializeApp } from "firebase/app";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB9w9LRrd1T8GLlJnO027dA95EY6Sm_uEA",
  authDomain: "jcup-task-management-system.firebaseapp.com",
  projectId: "jcup-task-management-system",
  storageBucket: "jcup-task-management-system.appspot.com",
  messagingSenderId: "1071402210342",
  appId: "1:1071402210342:web:82fa7aaa1ca179e17d02e7",
  measurementId: "G-BMG8WMNLXY",
});

export const adminCloakUser = ref(false);

export const getUserState = () =>
  new Promise<{ user: User | null; isAdmin: boolean }>((resolve, reject) =>
    onAuthStateChanged(
      getAuth(),
      (user) => {
        if (user) {
          getIdTokenResult(user, true)
            .then((tokens) => {
              const adminToken = tokens.claims.admin ? true : false;
              resolve({ user: user, isAdmin: adminToken });
            })
            .catch(reject);
        } else {
          resolve({ user: user, isAdmin: false });
        }
      },
      reject
    )
  );

export const useAuthState = () => {
  const user: Ref<User | null> = ref(null);
  const error: Ref<Error | null> = ref(null);
  const isAdmin: Ref<boolean> = ref(false);
  const adminCloakUser: Ref<boolean> = ref(false);

  const auth = getAuth();
  let unsubscribe: Unsubscribe;
  onMounted(() => {
    unsubscribe = onAuthStateChanged(
      auth,
      (u) => {
        user.value = u;
        if (u) {
          getIdTokenResult(u, true)
            .then((tokens) => {
              const adminToken = tokens.claims.admin ? true : false;
              isAdmin.value = adminToken;
            })
            .catch((e) => (error.value = e));
        }
      },
      (e) => (error.value = e)
    );
  });
  onUnmounted(() => unsubscribe());

  const isAuthenticated = computed(() => user.value != null);

  const logout = () => {
    return signOut(auth);
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const register = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  return {
    user,
    error,
    isAdmin,
    isAuthenticated,
    login,
    logout,
    register,
  };
};
