import { UserListEntry } from "./../types/UserListEntry";
import { getFunctions, httpsCallable } from "@firebase/functions";

export class CloudFunctionsController {
  constructor() {}

  getUsersList() {
    return httpsCallable<null, UserListEntry[]>(
      getFunctions(),
      "getUsersList"
    )();
  }
  setUserAsAdmin(userId: string) {
    return httpsCallable<{ uid: string }, boolean>(
      getFunctions(),
      "setUserAsAdmin"
    )({ uid: userId });
  }
  revokeUserAdmin(userId: string) {
    return httpsCallable<{ uid: string }, boolean>(
      getFunctions(),
      "revokeUserAdmin"
    )({ uid: userId });
  }
  deleteUser(userId: string) {
    return httpsCallable<{ uid: string }, boolean>(
      getFunctions(),
      "deleteUser"
    )({ uid: userId });
  }
  forceSendMail(type: "DAILY" | "NEW") {
    return httpsCallable(getFunctions(), "forceUpdates")(type);
  }
}
