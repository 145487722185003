import { CloudFunctionsController } from "./cloudFunctionsController";
import { enableMultiTabIndexedDbPersistence } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { Analytics } from "@firebase/analytics";
import { ActiveCourseController } from "./activeCourseController";
import { CourseAnswerController } from "./answerController";
import { CourseController } from "./courseController";
import { TaskController } from "./taskController";
import {
  clearIndexedDbPersistence,
  Firestore,
  getFirestore,
  terminate,
} from "@firebase/firestore";
import { Auth } from "@firebase/auth";
import { Functions } from "@firebase/functions";
import { FirebaseApp, getApp } from "@firebase/app";
export default class ApiController {
  tasks: TaskController;
  courses: CourseController;
  answers: CourseAnswerController;
  activeCourses: ActiveCourseController;
  functions: CloudFunctionsController;

  analytics: Analytics;
  db: Firestore;
  auth: Auth;
  app: FirebaseApp;
  func: Functions;

  constructor() {
    //Initialize Firebase Services
    this.app = getApp();
    this.analytics = getAnalytics();
    this.auth = getAuth();
    this.db = getFirestore();
    this.func = getFunctions();

    //Enable Persistence
    try {
      enableMultiTabIndexedDbPersistence(this.db).catch((error) => {
        this.logError("EnablePersistence", error);
      });
    } catch (error) {
      this.logError("EnablePersistence", error);
    }

    //Create Controllers
    this.tasks = new TaskController();
    this.courses = new CourseController();
    this.answers = new CourseAnswerController();
    this.activeCourses = new ActiveCourseController();
    this.functions = new CloudFunctionsController();
  }

  errors: string[] = [];
  logError(location: string, error: any) {
    this.errors.push(
      `Error at location [${location}] @${Date.toString()} : ${error}`
    );
    console.error(
      `Error at location [${location}] @${Date.toString()} : `,
      error
    );
  }

  clearPersistence() {
    terminate(this.db).then(() => {
      clearIndexedDbPersistence(this.db)
        .then(() => {
          this.db = getFirestore();
        })
        .catch((error) => {
          this.logError("ClearPersistence", error);
        });
    });
  }

  destroy() {
    this.tasks.destroy();
    this.activeCourses.destroy();
    this.courses.destroy();
  }
}
