import { Ref, ref } from "@vue/reactivity";
import { Unsubscribe } from "@firebase/util";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocsFromCache,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

export class BaseController<T> {
  docs: Ref<T[]> = ref([]);

  private collectionRef: CollectionReference<T>;
  private collectionUnsub!: Unsubscribe;

  private lsKey: string;
  private base: string;
  constructor(base: string, localStorageKey: string, live: boolean = true) {
    this.lsKey = localStorageKey;
    this.base = base;
    this.collectionRef = collection(
      getFirestore(),
      base
    ) as CollectionReference<T>;

    //1. Get documents from cache
    getDocsFromCache(this.collectionRef).then((docs) => {
      //2. Determine the latest lastModified date if we have documents, default otherwise
      let lastDate: Date = new Date(2000, 1);
      if (!docs.empty) {
        docs.docs.forEach((doc) => {
          let data: any = doc.data();
          if (data.lastModified > lastDate) lastDate = data.lastModified;
        });
      }

      //3. Create query with lastModified
      const documentQuery = query(
        this.collectionRef,
        orderBy("lastModified", "desc"),
        where("lastModified", ">", lastDate)
      );

      //4. Create Listener - if a document's lastModified is < lastDate it will be retrieved
      // from the cache, if anything changes however we still have reactive updating
      this.collectionUnsub = onSnapshot(documentQuery, (snapshot) => {
        if (!live) this.collectionUnsub();
        const changes = snapshot.docChanges();
        let cacheCount = 0;
        let serverCount = 0;
        changes.forEach((c) => {
          if (c.doc.metadata.fromCache) {
            cacheCount++;
          } else {
            serverCount++;
          }
          if (c.type === "added") {
            this.docs.value.push({
              ...c.doc.data(),
              uid: c.doc.id,
            });
          }
          if (c.type === "modified") {
            const index = this.docs.value.findIndex(
              (d: any) => d.uid === c.doc.id
            );
            this.docs.value.splice(index, 1, c.doc.data());
          }
          if (c.type === "removed") {
            const index = this.docs.value.findIndex(
              (d: any) => d.uid === c.doc.id
            );
            this.docs.value.splice(index, 1);
          }
        });
        //5. Store some rudimentary usage data to local storage
        this.updateUsageStatistics(serverCount, cacheCount);

        //6. Order the results
        this.docs.value.sort((a: any, b: any) => {
          return b.lastModified - a.lastModified;
        });
      });
    });
  }

  async readOne(id: string) {
    const docRef = doc<T>(this.collectionRef, `${id}`);
    return (await getDoc(docRef)).data() as T;
  }
  create(item: T | any) {
    return addDoc(this.collectionRef, {
      ...item,
      lastModified: serverTimestamp(),
    }).then((document) => {
      return updateDoc(document, { uid: document.id });
    });
  }
  delete(id: string) {
    const docRef = doc<T>(this.collectionRef, `${id}`);
    return deleteDoc(docRef);
  }
  update(id: string, item: T) {
    const docRef = doc<T>(this.collectionRef, `${id}`);
    const updated = {
      ...item,
      lastModified: serverTimestamp(),
      uid: docRef.id,
    };
    updateDoc(docRef, updated as any);
  }

  private updateUsageStatistics(server: number, cache: number) {
    const itemsString = window.localStorage.getItem(this.lsKey);
    if (itemsString) {
      const items = JSON.parse(itemsString);
      window.localStorage.setItem(
        this.lsKey,
        JSON.stringify({
          serverCount: items.serverCount + server,
          cacheCount: items.cacheCount + cache,
          date: new Date(Date.now()),
        })
      );
    }
  }

  destroy() {
    this.collectionUnsub();
  }
}
